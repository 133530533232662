import {useEffect} from "react";
import {setUnknown} from "app/actions/unknown";
import {get as getUnknown} from "app/requests/public/unknowns";
import useAppContext from "lib/hooks/use-app-context";

export default function useUnknown(id) {
  const {dispatch, unknown} = useAppContext();

  useEffect(() => {
    if(unknown && unknown.id === id) { return; }

    getUnknown(id).then((data) => dispatch(setUnknown({...data.case, type: "Case"})));
  }, [id]);

  return unknown;
}
