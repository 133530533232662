import {SET_CATEGORY} from "app/actions/category";

export default function category({action, state}) {
  switch(action.type) {
    case SET_CATEGORY:
      return action.category ? {...action.category} : null;
    default:
      return state;
  }
}
