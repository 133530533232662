import {useEffect} from "react";
import {Link} from "react-router-dom";
import {setTest} from "app/actions/test";
import {get as getTest} from "app/requests/public/tests";
import Content from "components/helpers/action-text/content";
import DeepDive from "components/helpers/deep-dive";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";

export default function Test() {
  const {dispatch, page, test} = useAppContext();

  useEffect(() => {
    getTest(page.test_id).then((data) => dispatch(setTest(data.test)));
  }, [page.test_id]);

  if(!test) { return <Loading />; }

  return (
    <div className="max-w-screen-md mx-auto">
      <h1>{test.name}</h1>
      <div className="custom-html">
        <Content className="mb-4" html={page.content} />
      </div>
      <DeepDive content={page.deep_dive} />
      <div className="my-4">There are {test.questions.length} questions</div>
      <Link className="btn btn-green" to={`/tests/${test.id}`}>Begin</Link>
    </div>
  );
}
