import PropTypes from "prop-types";
import {useState} from "react";
import Content from "components/helpers/action-text/content";
import {isBlankHTML} from "lib/ext/string";

function DeepDive({content = ""}) {
  const [show, setShow] = useState(false);

  if(isBlankHTML(content)) { return null; }

  return (
    <>
      <div className="btn-group block my-2 text-center">
        <button className="btn btn-sm btn-green" onClick={() => setShow(!show)} type="button">Deep Dive</button>
      </div>
      {show && (
        <div className="custom-html text-center">
          <Content html={content} />
        </div>
      )}
    </>
  );
}

DeepDive.propTypes = {content: PropTypes.string};

export default DeepDive;
