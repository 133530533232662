import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {create as createRecord} from "app/requests/admin/questions";
import {withoutBlankValues} from "lib/ext/object";
import {valueFrom, valueFromTarget} from "lib/form";
import useAppContext from "lib/hooks/use-app-context";
import usePrompt from "lib/hooks/use-prompt";
import Form from "./form";

export default function AdminTestQuestionsNew() {
  const {dispatch} = useAppContext();
  const navigate = useNavigate();
  const {testID} = useParams();
  const [block, setBlock] = useState(false);
  const [record, setRecord] = useState({
    name: "",
    rank: 100,
    test_id: testID
  });

  usePrompt({when: block});

  const onChange = ({target}) => {
    setBlock(true);
    setRecord({...record, [target.name]: valueFromTarget(target)});
  };
  const onSubmit = (e) => {
    e.preventDefault();

    const {answers, ...recordChanges} = withoutBlankValues(record);
    if(answers) { recordChanges.answers_attributes = answers; }

    setBlock(false);
    createRecord({test_question: recordChanges}).then(({message, success}) => {
      if(success) {
        navigate(`/admin/tests/${testID}`, {message, type: "success"});
      } else {
        dispatch(createNotification({content: message, type: "error"}));
      }
    });
  };
  const value = (name, defaultValue) => valueFrom({defaultValue, name, objects: [record]});

  return (
    <div>
      <h1 className="text-center">New Test Question</h1>
      <Form {...{onChange, onSubmit, value}} />
    </div>
  );
}
