import PropTypes from "prop-types";
import {createNotification} from "app/actions/notifications";
import {destroy as destroyRecord} from "app/requests/admin/questions";
import {displayDate} from "lib/ext/string";
import useAppContext from "lib/hooks/use-app-context";

function AdminTestQuestionsListRow({onDestroy, record}) {
  const {dispatch} = useAppContext();
  const onDelete = () => {
    if(!window.confirm("Are you sure you want to delete this question?")) { return; }

    destroyRecord(record.id).then(({message, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "error"}));

      if(success) { onDestroy(record.id); }
    });
  };

  return (
    <tr>
      <td>{record.rank}</td>
      <td>{record.name}</td>
      <td>{record.tag ? record.tag.name : "None"}</td>
      <td>{displayDate(record.created_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-blue" href={`/admin/questions/${record.id}`}>Edit</a>
          <button type="button" className="btn btn-sm btn-red" onClick={onDelete}>Destroy</button>
        </div>
      </td>
    </tr>
  );
}

AdminTestQuestionsListRow.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  record: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    rank: PropTypes.number.isRequired,
    tag: PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  }).isRequired
};

export default AdminTestQuestionsListRow;
