import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {get as getSection, update as updateSection} from "app/requests/admin/sections";
import FormWithFiles from "components/helpers/form/with-files";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";
import usePrompt from "lib/hooks/use-prompt";
import Content from "./content";

export default function AdminSectionsEdit() {
  const [changes, setChanges] = useState({});
  const {dispatch} = useAppContext();
  const navigate = useNavigate();
  const {id} = useParams();
  const [block, setBlock] = useState(false);
  const [section, setSection] = useState(null);
  const onChange = (updatedRecord) => {
    const contents = (changes.contents || section.contents).map((record) => (
      record.id === updatedRecord.id ? updatedRecord : record
    ));

    setBlock(true);
    setChanges({...changes, contents});
  };
  const onSubmit = (files) => {
    const {contents, ...updates} = changes;

    updates.contents_attributes = contents.map((record) => (
      files[record.id] ? {...record, value: files[record.id]} : record
    ));

    if(Object.keys(updates).length === 0) {
      return dispatch(createNotification({
        content: "Please make changes before submitting",
        type: "error"
      }));
    }

    setBlock(false);
    updateSection(section.id, {section: updates}).then((data) => {
      if(data.success) {
        navigate("/admin/sections", {message: data.message, type: "success"});
      } else {
        dispatch(createNotification({content: data.message, type: "error"}));
      }
    });
  };

  usePrompt({when: block});
  useEffect(() => {
    getSection(id).then((data) => setSection(data.section));
  }, []);

  if(!section) { return <Loading />; }

  return (
    <div className="max-w-sm mx-auto">
      <FormWithFiles onSubmit={onSubmit}>
        <h1 className="text-center">Edit Section</h1>
        <h2 className="text-center">{section.name}</h2>
        {(changes.contents || section.contents).map((record) => (
          <Content key={record.id} onChange={onChange} record={record} />
        ))}
        <div className="text-center">
          <button className="btn btn-blue" type="submit">Submit</button>
        </div>
      </FormWithFiles>
    </div>
  );
}
