import {useState} from "react";
import {createNotification} from "app/actions/notifications";
import {setUser} from "app/actions/user";
import {create as createSession} from "app/requests/public/session";
import useAppContext from "lib/hooks/use-app-context";

export default function Session() {
  const {dispatch} = useAppContext();
  const [user, setChanges] = useState({email: "", password: ""});
  const onChange = ({target: {name, value}}) => (
    setChanges({...user, [name]: value})
  );
  const onSubmit = (e) => {
    e.preventDefault();

    createSession({user}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "error"
      }));

      if(data.success) { dispatch(setUser(data.user)); }
    });
  };

  return (
    <div className="max-w-sm mx-auto">
      <h1 className="text-center">Sign In</h1>
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <input
            id="session-email"
            name="email"
            onChange={onChange}
            placeholder="Email"
            required={true}
            type="email"
            value={user.email}
          />
        </div>
        <div className="mb-4">
          <input
            id="session-password"
            name="password"
            onChange={onChange}
            placeholder="Password"
            required={true}
            type="password"
            value={user.password}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue mx-2">Sign In</button>
          <a className="btn btn-link" href={`/forgot-password?email=${user.email}`}>Forgot Password?</a>
        </div>
      </form>
    </div>
  );
}
