import {useState} from "react";
import {Navigate} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {setUser} from "app/actions/user";
import {update as updateUser} from "app/requests/public/user";
import Checkbox from "components/helpers/form/checkbox";
import File from "components/helpers/form/file";
import FormWithFiles from "components/helpers/form/with-files";
import {valueFrom, valueFromTarget} from "lib/form";
import useAppContext from "lib/hooks/use-app-context";

export default function Profile() {
  const {dispatch, user} = useAppContext();
  const [changes, setChanges] = useState({});
  const onChange = ({target}) => (
    setChanges({...changes, [target.name]: valueFromTarget(target)})
  );
  const onSubmit = (files) => {
    const updates = {...changes, ...files};

    if(Object.keys(updates).length === 0) {
      return dispatch(createNotification({
        content: "Please make changes before submitting",
        type: "error"
      }));
    }

    updateUser({user: updates}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "error"
      }));

      if(data.success) {
        dispatch(setUser(data.user));
        setChanges({});
      }
    });
  };
  const value = (name, defaultValue) => valueFrom({defaultValue, name, objects: [changes, user]});

  if(!user) { return <Navigate to="/" />; }

  return (
    <div className="max-w-sm mx-auto">
      <h1 className="text-center">Profile</h1>
      <FormWithFiles onSubmit={onSubmit}>
        <div className="mb-4">
          <input
            id="user-first-name"
            name="first_name"
            onChange={onChange}
            placeholder="First Name"
            required={true}
            type="text"
            value={value("first_name")}
          />
        </div>
        <div className="mb-4">
          <input
            id="user-last-name"
            name="last_name"
            onChange={onChange}
            placeholder="Last Name"
            required={true}
            type="text"
            value={value("last_name")}
          />
        </div>
        <div className="mb-4">
          <input
            id="user-email"
            name="email"
            onChange={onChange}
            placeholder="Email"
            required={true}
            type="email"
            value={value("email")}
          />
        </div>
        <div className="mb-4">
          <input
            id="user-password"
            name="password"
            onChange={onChange}
            placeholder="Password"
            type="password"
            value={changes.password || ""}
          />
        </div>
        <div className="mb-4">
          <input
            id="user-password-confirmation"
            name="password_confirmation"
            onChange={onChange}
            placeholder="Password Confirmation"
            type="password"
            value={changes.password_confirmation || ""}
          />
        </div>
        <div className="mb-4">
          <File id="user-image" name="image" onChange={onChange} />
          {value("image") && <img alt="Profile" src={value("image")} />}
        </div>
        <div className="mb-4">
          <Checkbox
            checked={"case_notifications" in changes ? changes.case_notifications : user.case_notifications}
            id="user-case-notifications"
            label="Case Notifications"
            name="case_notifications"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <Checkbox
            checked={"daily_notifications" in changes ? changes.daily_notifications : user.daily_notifications}
            id="user-daily-notifications"
            label="Daily Notifications"
            name="daily_notifications"
            onChange={onChange}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue px-5">Save Profile</button>
        </div>
      </FormWithFiles>
    </div>
  );
}
