import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {setPage} from "app/actions/page";
import useAppContext from "lib/hooks/use-app-context";
import Cases from "./cases";
import Category from "./category";
import Default from "./default";
import Home from "./home";
import Test from "./test";
import Random from "./random";

const getPage = ({pages, pathname}) => {
  if(pathname === "/") {
    return pages.find(({path}) => path === "home") || {
      content: "",
      name: "Home",
      path: "home",
      template: "home"
    };
  }

  return pages.find(({path}) => `/${path}` === pathname);
};

export default function Template() {
  const location = useLocation();
  const {dispatch, page, pages} = useAppContext();

  useEffect(() => {
    dispatch(setPage(getPage({pages, pathname: location.pathname})));
  }, [location.pathname, pages]);

  if(!page) { return null; }

  switch(page.template) {
    case "Cases": return <Cases />;
    case "Category": return <Category />;
    case "Home": return <Home />;
    case "Test": return <Test />;
    case "Random": return <Random />;
    default: return <Default />;
  }
}
