import {SET_TEST} from "app/actions/test";

export default function test({action, state}) {
  switch(action.type) {
    case SET_TEST:
      return action.test ? {...action.test} : null;
    default:
      return state;
  }
}
