import {createBrowserRouter, redirect, Navigate} from "react-router-dom";
import Container from "components/layout/container";
import ErrorBoundary from "components/layout/router-error-boundary";
import * as Pages from "components/pages";

export default function createRouter(initialState) {
  const {pages, user} = initialState;
  const requireAdmin = () => {
    if(!user) return redirect("/session");
    if(!user.admin) return redirect("/profile");

    return null;
  };
  const requireNoUser = () => {
    if(user) return redirect("/profile");

    return null;
  };
  const requireUser = () => {
    if(!user) return redirect("/session");

    return null;
  };
  const staticPages = pages.map(({path}) => (
    {Component: Pages.Public.Template, path: `/${path}`}
  ));

  return createBrowserRouter(
    [{
      children: [
        {element: <Navigate to="/" />, path: "/home"},
        ...[
          {Component: Pages.Public.Template, path: "/"},
          {Component: Pages.Public.ForgotPassword, loader: requireNoUser, path: "/forgot-password"},
          {Component: Pages.Public.Profile, loader: requireUser, path: "/profile"},
          {Component: Pages.Public.Register, loader: requireNoUser, path: "/register"},
          {Component: Pages.Public.Session, loader: requireNoUser, path: "/session"},
          {Component: Pages.Public.Tags.Show, path: "/tags/:id"},
          {Component: Pages.Public.Tests.Show, path: "/tests/:id"},
          {
            children: [
              {element: <Navigate to="question" />, path: "/unknowns/:id/"},
              {Component: Pages.Public.Unknowns.Answer, path: "/unknowns/:id/answer"},
              {Component: Pages.Public.Unknowns.Comments, path: "/unknowns/:id/comments"},
              {Component: Pages.Public.Unknowns.DeepDive, path: "/unknowns/:id/deep-dive"},
              {Component: Pages.Public.Unknowns.Question, path: "/unknowns/:id/question"},
              {Component: Pages.Public.Unknowns.QuickTake, path: "/unknowns/:id/quick-take"}
            ],
            path: "/unknowns/:id"
          }
        ].map((route) => ({
          children: [route],
          path: route.path,
          Component: Pages.Public.Container
        })),
        {element: <Navigate to="/admin/sections" />, loader: requireAdmin, path: "/admin"},
        {
          children: [
            {Component: Pages.Public.Categories.Show, path: "/categories/:id"}
          ].map((route) => ({...route, loader: requireAdmin})),
          Component: Pages.Admin.Container
        },
        {
          children: [
            {Component: Pages.Admin.Cases.New, path: "/admin/cases/new"},
            {Component: Pages.Admin.Cases.Edit, path: "/admin/cases/:id"},
            {Component: Pages.Admin.Cases.List, path: "/admin/cases"},
            {Component: Pages.Admin.Categories.New, path: "/admin/categories/new"},
            {Component: Pages.Admin.Categories.Edit, path: "/admin/categories/:id"},
            {Component: Pages.Admin.Categories.List, path: "/admin/categories"},
            {Component: Pages.Admin.Pages.New, path: "/admin/pages/new"},
            {Component: Pages.Admin.Pages.Edit, path: "/admin/pages/:id"},
            {Component: Pages.Admin.Pages.List, path: "/admin/pages"},
            {Component: Pages.Admin.Sections.Edit, path: "/admin/sections/:id"},
            {Component: Pages.Admin.Sections.List, path: "/admin/sections"},
            {Component: Pages.Admin.Tags.New, path: "/admin/tags/new"},
            {Component: Pages.Admin.Tags.Edit, path: "/admin/tags/:id"},
            {Component: Pages.Admin.Tags.List, path: "/admin/tags"},
            {Component: Pages.Admin.Questions.Edit, path: "/admin/questions/:id"},
            {Component: Pages.Admin.Questions.New, path: "/admin/tests/:testID/questions/new"},
            {Component: Pages.Admin.Tests.New, path: "/admin/tests/new"},
            {Component: Pages.Admin.Tests.Edit, path: "/admin/tests/:id/edit"},
            {Component: Pages.Admin.Tests.Show, path: "/admin/tests/:id"},
            {Component: Pages.Admin.Tests.List, path: "/admin/tests"},
            {Component: Pages.Admin.Users.New, path: "/admin/users/new"},
            {Component: Pages.Admin.Users.Edit, path: "/admin/users/:id"},
            {Component: Pages.Admin.Users.List, path: "/admin/users"}
          ].map((route) => ({...route, loader: requireAdmin})),
          path: "/admin",
          Component: Pages.Admin.Container
        },
        ...staticPages,
        {Component: Pages.NotFound}
      ],
      Component: Container,
      ErrorBoundary
    }]
  );
}
