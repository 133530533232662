import PropTypes from "prop-types";

function Results({testResponse}) {
  const total = testResponse.question_responses.length;

  return (
    <div>
      <h1 className="text-center">Results</h1>
      <h3 className="text-center">{testResponse.score}/{total}</h3>
    </div>
  );
}

Results.propTypes = {
  testResponse: PropTypes.shape({
    score: PropTypes.number.isRequired,
    question_responses: PropTypes.arrayOf(
      PropTypes.shape({id: PropTypes.number.isRequired}).isRequired
    ).isRequired
  }).isRequired
};

export default Results;
