import {useRouteError} from "react-router-dom";

export default function RouterErrorBoundary() {
  const {error} = useRouteError();
  console.log(error); // eslint-disable-line no-console
  if(!error) { return; }

  return (
    <div className="container">
      <div className="md:mx-auto md:w-1/3 text-center">
        <h1>There was a problem</h1>
        <p>Please reload the page and try again. If the problem persists, please email <a href="mailto:tom@tomify.me">tom@tomify.me</a> with the following information.</p>
        <p>Error: {error.message}</p>
        <p>Page: {window.location.href}</p>
      </div>
    </div>
  );
}
