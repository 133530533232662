import {useState} from "react";
import {createNotification} from "app/actions/notifications";
import {forgotPassword} from "app/requests/public/session";
import useAppContext from "lib/hooks/use-app-context";
import useQueryParams from "lib/hooks/use-query-params";

export default function ForgotPassword() {
  const {dispatch} = useAppContext();
  const params = useQueryParams();
  const [email, setEmail] = useState(params.get("email") || "");
  const onSubmit = (e) => {
    e.preventDefault();

    forgotPassword({email}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "error"
      }));
    });
  };

  return (
    <div className="max-w-sm mx-auto">
      <h1 className="text-center">Forgot Password</h1>
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <input
            id="session-email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email"
            required={true}
            type="email"
            value={email}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue px-5">Submit</button>
        </div>
      </form>
    </div>
  );
}
