import category from "app/reducers/category";
import notifications from "app/reducers/notifications";
import page from "app/reducers/page";
import tag from "app/reducers/tag";
import test from "app/reducers/test";
import unknown from "app/reducers/unknown";
import user from "app/reducers/user";
import combineReducers from "lib/combine-reducers";

const defaultReducer = ({state}) => state;

export const createInitialState = (props) => ({
  app: props.app,
  notifications: props.notifications || [],
  page: {},
  pages: props.pages,
  sections: props.sections,
  user: props.user
});

export default combineReducers({
  app: defaultReducer,
  category,
  notifications,
  page,
  pages: defaultReducer,
  sections: defaultReducer,
  tag,
  test,
  unknown,
  user
});
