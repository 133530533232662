import Content from "components/helpers/action-text/content";
import DeepDive from "components/helpers/deep-dive";
import useAppContext from "lib/hooks/use-app-context";

export default function Default() {
  const {page} = useAppContext();

  return (
    <div className="max-w-screen-md mx-auto">
      <div className="custom-html">
        <Content html={page.content} />
      </div>
      <DeepDive content={page.deep_dive} />
    </div>
  );
}
