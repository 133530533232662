import PropTypes from "prop-types";
import {useState} from "react";
import {createNotification} from "app/actions/notifications";
import {setUser} from "app/actions/user";
import {create as createSession} from "app/requests/public/session";
import useAppContext from "lib/hooks/use-app-context";

function SignIn({email, onBack}) {
  const {dispatch} = useAppContext();
  const [password, setPassword] = useState("");
  const onChange = ({target: {value}}) => setPassword(value);
  const onSubmit = (e) => {
    e.preventDefault();

    createSession({user: {email, password}}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "error"
      }));

      if(data.success) { dispatch(setUser(data.user)); }
    });
  };

  return (
    <div>
      <h1 className="text-center">Sign In</h1>
      <div className="max-w-sm mx-auto">
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <input
              disabled={true}
              id="user-email"
              name="email"
              placeholder="Email"
              required={true}
              type="email"
              value={email}
            />
          </div>
          <div className="mb-4">
            <input
              id="user-password"
              name="password"
              onChange={onChange}
              placeholder="Password"
              required={true}
              type="password"
              value={password}
            />
          </div>
          <div className="mb-4 text-center">
            <button className="btn btn-gray mx-2" onClick={onBack} type="button">Back</button>
            <button className="btn btn-blue mx-2" type="submit">Sign In</button>
          </div>
          <div className="text-center">
            <a className="btn btn-link" href={`/forgot-password?email=${email}`}>Forgot Password?</a>
          </div>
        </form>
      </div>
    </div>
  );
}

SignIn.propTypes = {
  email: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired
};

export default SignIn;
