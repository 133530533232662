import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {get as getRecord} from "app/requests/admin/tests";
import TestQuestionsList from "components/pages/admin/questions/list";
import Loading from "components/pages/loading";
import {displayDate} from "lib/ext/string";

export default function AdminTestsShow() {
  const {id} = useParams();
  const [record, setRecord] = useState(null);

  useEffect(() => {
    getRecord(id).then((data) => setRecord(data.test));
  }, []);

  if(!record) { return <Loading />; }

  return (
    <div>
      <h1 className="text-center">Edit Test</h1>
      <div className="text-center">
        <div className="btn-group">
          <a className="btn btn-yellow" href={`/admin/tests/${record.id}/questions/new`}>Add Question</a>
          <a className="btn btn-blue" href={`/admin/tests/${record.id}/edit`}>Edit Test</a>
        </div>
      </div>
      <div className="max-w-screen-lg mx-auto my-5 ">
        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th>Admin</th>
                <th>Name</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{record.admin.first_name} {record.admin.last_name}</td>
                <td>{record.name}</td>
                <td>{displayDate(record.created_at)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h2 className="text-center">Questions</h2>
      <TestQuestionsList records={record.questions} />
    </div>
  );
}
