import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {setPage} from "app/actions/page";
import Category from "components/pages/public/template/category";
import useAppContext from "lib/hooks/use-app-context";
import useQueryParams from "lib/hooks/use-query-params";

export default function CategoriesShow() {
  const {dispatch} = useAppContext();
  const {id} = useParams();
  const {template} = useQueryParams();

  useEffect(() => {
    dispatch(setPage({category_id: id, content: "Admin Demo Page", template: template || "Cases"}));
  }, [id, template]);

  return <Category />;
}
