import {useState} from "react";
import {getStatus} from "app/requests/public/user";
import SignIn from "./sign-in";
import SignUp from "./sign-up";

export default function User() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(null);
  const onBack = () => setStatus(null);
  const onChange = ({target: {value}}) => setEmail(value);
  const onSubmit = (e) => {
    e.preventDefault();

    getStatus({email}).then((data) => setStatus(data.status));
  };

  if(status && !status.exists) { return <SignUp email={email} onBack={onBack} />; }
  if(status && status.exists) { return <SignIn email={email} onBack={onBack} />; }

  return (
    <div>
      <h1 className="text-center">Authenticate</h1>
      <div className="max-w-sm mx-auto">
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <input
              id="user-email"
              name="email"
              onChange={onChange}
              placeholder="Email"
              required={true}
              type="email"
              value={email}
            />
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-blue mx-2">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
