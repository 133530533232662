import {useState} from "react";
import {createNotification} from "app/actions/notifications";
import {setUser} from "app/actions/user";
import {create as createUser} from "app/requests/public/user";
import useAppContext from "lib/hooks/use-app-context";

export default function Register() {
  const {dispatch} = useAppContext();
  const [changes, setChanges] = useState({
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: ""
  });
  const onChange = ({target: {name, value}}) => (
    setChanges({...changes, [name]: value})
  );
  const onSubmit = (e) => {
    e.preventDefault();

    const {
      password,
      password_confirmation: passwordConfirmation,
      ...record
    } = {...changes};

    if(password) { record.password = password; }
    if(passwordConfirmation) { record.password_confirmation = passwordConfirmation; }

    createUser({user: record}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "error"
      }));

      if(data.success) { dispatch(setUser(data.user)); }
    });
  };

  return (
    <div className="max-w-sm mx-auto">
      <h1 className="text-center">Register</h1>
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <input
            id="register-email"
            name="email"
            onChange={onChange}
            placeholder="Email"
            required={true}
            type="email"
            value={changes.email}
          />
        </div>
        <div className="mb-4">
          <input
            id="register-first-name"
            name="first_name"
            onChange={onChange}
            placeholder="First Name"
            required={true}
            type="text"
            value={changes.first_name}
          />
        </div>
        <div className="mb-4">
          <input
            id="register-last-name"
            name="last_name"
            onChange={onChange}
            placeholder="Last Name"
            required={true}
            type="text"
            value={changes.last_name}
          />
        </div>
        <div className="mb-4">
          <input
            id="register-password"
            name="password"
            onChange={onChange}
            placeholder="Password (optional)"
            type="password"
            value={changes.password}
          />
        </div>
        <div className="mb-4">
          <input
            id="register-password-confirmation"
            name="password_confirmation"
            onChange={onChange}
            placeholder="Password Confirmation (optional)"
            type="password"
            value={changes.password_confirmation}
          />
        </div>
        <div className="text-center">
          <button className="btn btn-blue mx-2" type="submit">Sign Up</button>
        </div>
      </form>
    </div>
  );
}
