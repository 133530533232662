import {useEffect} from "react";
import {Link} from "react-router-dom";
import {setUnknown} from "app/actions/unknown";
import {getRandom as getUnknown} from "app/requests/public/unknowns";
import Content from "components/helpers/action-text/content";
import DeepDive from "components/helpers/deep-dive";
import Loading from "components/pages/loading";
import Links from "components/pages/public/unknowns/links";
import useAppContext from "lib/hooks/use-app-context";

export default function Category() {
  const {dispatch, page, unknown} = useAppContext();

  const random = () => {
    getUnknown().then((data) => dispatch(setUnknown(data.case)));
  };

  useEffect(() => { random(); }, []);

  if(!unknown) { return <Loading />; }

  return (
    <div className="max-w-screen-md mx-auto">
      <div className="custom-html">
        <Content className="mb-4" html={page.content} />
      </div>
      <DeepDive content={page.deep_dive} />
      <h1>Random Unknown</h1>
      <div className="btn-group block mb-4">
        <button className="btn btn-green" onClick={random} type="button">New Unknown</button>
        <Link className="btn btn-yellow" to={`/unknowns/${unknown.id}`}>Permalink</Link>
      </div>
      <div className="custom-html">
        <Content className="mb-4" html={unknown.question || "There is no question yet for this unknown"} />
      </div>
      <Links />
    </div>
  );
}
