import {SET_USER} from "app/actions/user";

export default function user({action, state}) {
  switch(action.type) {
    case SET_USER:
      return action.user ? {...action.user} : null;
    default:
      return state;
  }
}
