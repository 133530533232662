import {Link} from "react-router-dom";
import useAppContext from "lib/hooks/use-app-context";

export default function Groups() {
  const {category} = useAppContext();

  return (
    <div className="flex flex-wrap justify-between">
      {category.tags.filter(({cases}) => cases.length !== 0).map((tag) => (
        <div key={tag.id} className="mb-4 w-full sm:w-[calc(50%-1rem)]">
          <h2>{tag.name}</h2>
          <div className="divide-y">
            {tag.cases.map((record, index) => (
              <div key={record.id} className="p-3">
                <Link to={`/unknowns/${record.id}/question`}>
                  {category.display === "Name" ? record.name : `${tag.name} - ${index + 1}`}
                </Link>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
