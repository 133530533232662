import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import Content from "components/helpers/action-text/content";
import Loading from "components/pages/loading";
import useUnknown from "lib/hooks/use-unknown";
import Links from "./links";

function QuickTake({id = null}) {
  const params = useParams();
  const unknown = useUnknown(id || params.id);

  if(!unknown) { return <Loading />; }

  return (
    <div className="max-w-screen-md mx-auto">
      <h1>Quick Take</h1>
      <div className="custom-html">
        <Content className="mb-4" html={unknown.quick_take || "There is no quick take yet for this unknown"} />
      </div>
      <Links />
    </div>
  );
}

QuickTake.propTypes = {id: PropTypes.number};

export default QuickTake;
